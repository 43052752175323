<template>
  <component :is="icon" v-bind="$attrs" class="icon" v-on="$listeners" />
</template>

<script>
export default {
  name: 'Icon',

  props: {
    name: String,
  },

  computed: {
    icon() {
      return () => import(`@/assets/svg/${this.name.toLowerCase()}.svg?inline`)
    },
  },
}
</script>

<style lang="scss">
.icon {
  animation: appear $default-transition;
}
</style>
