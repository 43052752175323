<template>
  <div :class="['abstraction', { 'abstraction--no-border': noBorder }]">
    <h3 class="abstraction__title">
      {{ title }}
    </h3>
    <nuxt-link class="abstraction__link" :to="link">
      Показать все <CIcon class="abstraction__icon" name="chevron-down" />
    </nuxt-link>

    <div class="abstraction__content">
      <slot />
    </div>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'

export default {
  name: 'CardsAbstraction',

  components: {
    CIcon,
  },

  props: {
    title: String,
    link: {
      type: String,
      default: '/',
    },
    noBorder: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.abstraction {
  padding: rem(22) 0;
  border-bottom: rem(1) dashed #bfa97d;
  &--no-border {
    border-bottom: unset;
  }

  &__title {
    display: inline-block;
    margin: 0 rem(10) rem(16) 0;
    color: $text-color;
    font-size: rem(22);
  }

  &__link {
    display: inline-flex;
    align-items: center;
    color: $color-green;
    font-size: rem(13);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $dark-green2;
      text-decoration: underline;
      .abstraction__icon {
        fill: $dark-green2;
      }
    }
  }

  &__icon {
    height: rem(7);
    margin: rem(1) 0 0 rem(6);
    line-height: 1;
    transform: rotate(-90deg);
    fill: $color-green;
    @include base-width(7);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-9);
  }
}
</style>
